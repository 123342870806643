var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mt-4  px-5"},[_c('h2',[_vm._v("Schiessbuch")]),_c('v-alert',{attrs:{"dense":"","type":"info"}},[_c('b',[_vm._v("Tipp:")]),_c('div',{},[_vm._v("Hier werden alle beendeten Einträge von dir angezeigt. "),_c('br'),_vm._v(" Möchtest du von deinem Verein, ein Training bestätigt bekommen, so klicke auf "),_c('i',{staticClass:"fas fa-hands-helping"}),_vm._v(" \"bestätigen\". "),_c('br'),_c('br'),_c('i',{staticClass:"fas fa-user-graduate"}),_vm._v(" Dein Schuetzenbuch kannst du exportieren, falls du es mal bei einem Amt vorlegen möchtest. ")])]),_c('v-data-table',{ref:"myShootingbook",staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.comp_myShootingbook,"sort-by":['created_at'],"sort-desc":[true],"item-class":_vm.itemRowClass,"loading":_vm.comp_Progress_loading,"loader-height":"5"},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-spacer'),_c('shootingbook-p-d-f-export',{attrs:{"pdf_data":_vm.comp_myShootingbook,"name":_vm.comp_Name,"club_user":"user"}}),_c('shootingbook-excel-export',{attrs:{"excel_data":_vm.comp_myShootingbook,"name":_vm.comp_Name,"club_user":"user"}}),_c('v-btn',{attrs:{"icon":""},on:{"click":_vm.getMyShootingbook}},[_c('v-icon',[_vm._v("mdi-reload")])],1)],1)]},proxy:true},{key:"item.beginn",fn:function(ref){
var item = ref.item;
return [(item.club_id !=null)?_c('span',[_vm._v(_vm._s(new Date(item.beginn.replace(" ", "T")).toLocaleString([], {dateStyle: 'short', timeStyle: 'short'})))]):_c('span',[_vm._v(_vm._s(new Date(item.beginn.replace(" ", "T")).toLocaleString([], {dateStyle: 'short'})))])]}},{key:"item.ende",fn:function(ref){
var item = ref.item;
return [(item.club_id !=null)?_c('span',[_vm._v(_vm._s(new Date(item.ende.replace(" ", "T")).toLocaleString([], {dateStyle: 'short', timeStyle: 'short'})))]):_c('span',[_vm._v(_vm._s(new Date(item.beginn.replace(" ", "T")).toLocaleString([], {dateStyle: 'short'})))])]}},{key:"item.approval",fn:function(ref){
var item = ref.item;
return [(item.bestaetigung_holen === 1 && item.club_id != null)?_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(item.bestaetigt_vereinDatum === null),expression:"item.bestaetigt_vereinDatum === null"}],staticClass:"btn-warning",attrs:{"color":"warning"},on:{"click":function($event){return _vm.send_to_confirmation(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v("fas fa-thumbtack ")]),_vm._v(" zurücknehmen ")],1):_vm._e(),(( item.bestaetigung_holen === false || item.bestaetigung_holen == null || item.bestaetigung_holen === 0) && item.club_id != null)?_c('v-btn',{staticClass:"btn-info",attrs:{"color":"info"},on:{"click":function($event){return _vm.send_to_confirmation(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v("fas fa-hands-helping ")]),_vm._v(" bestätigen ")],1):_vm._e(),(_vm.check_bestaetigung_holen(item) === false && item.club_id === null)?_c('shootingbook-sign-entry',{directives:[{name:"show",rawName:"v-show",value:(item.bestaetigt_vereinDatum === null),expression:"item.bestaetigt_vereinDatum === null"}],attrs:{"item":item}}):_vm._e(),_c('shootingbook-show-signature',{directives:[{name:"show",rawName:"v-show",value:(item.bestaetigt_vereinDatum != null && item.club_id === null),expression:"item.bestaetigt_vereinDatum != null && item.club_id === null"}],attrs:{"item":item}})]}},{key:"item.approvaldate",fn:function(ref){
var item = ref.item;
return [(item.bestaetigt_vereinDatum !== null)?_c('span',[_c('span',[_vm._v(" Freigegeben am: "),_c('br'),_vm._v(" "+_vm._s(new Date(item.bestaetigt_vereinDatum.replace(" ", "T")).toLocaleString([], { dateStyle: 'short', timeStyle: 'short' }))+" ")])]):_vm._e()]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(item.bestaetigt_vereinDatum === null && _vm.check_bestaetigung_holen(item) === false)?_c('v-btn',{staticClass:"error ml-2",on:{"click":function($event){return _vm.delShootingBookEntry_Dialog(item)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-trash-can ")])],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"},on:{"click":_vm.initialize}},[_vm._v(" neu laden ")])]},proxy:true}])}),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Willst du diesen Eintrag wirklich löschen?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){_vm.dialogDelete = false; _vm.prepare_delete_item = {}}}},[_vm._v("Abbrechen")]),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":function($event){return _vm.delShootingBooKEntry()}}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }