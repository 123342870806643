import {API} from "../../../axios";
import swal from "sweetalert2";
import {handleRegistrationError} from "@/plugins/store/errorHandlerSwal";

const actions = {
    async getuwelcomeText({commit}) {

        await API.post('/getText',
            {
                text_search: 'uwelcome'
            })
            .then((response) => {
                commit('get_UWelcomeText', response.data.Text);
                // resolve(response)
            })
            .catch(error => {
                console.log(error);
            });
    },
    async getTextNewUser({commit}) {

        await API.post('/getText',
            {
                text_search: 'uNeu'
            })
            .then((response) => {
                commit('get_TextNewUser', response.data.Text);
                // resolve(response)
            })
            .catch(error => {
                console.log(error);
            });
    },

    async getTextueberlassung({commit}) {

        await API.post('/getText',
            {
                text_search: 'ueberlassung'
            })
            .then((response) => {
                commit('get_TextUeberlassung', response.data.Text);
                // resolve(response)
            })
            .catch(error => {
                console.log(error);
            });
    },

    async getEduData({commit, rootState}) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.get('/user/education')
            .then((resp) => {
                const allEdu = resp.data.allEdu
                const myEdu = resp.data.eduDaten
                commit('get_Education', {allEdu, myEdu})

            })
            .catch(error => {
                console.log(error);
            });
    },

    async getWeapons({commit, rootState}) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.get('/user/weapons')
            .then((resp) => {
                const data = resp.data
                commit('get_Weapons', data)
            })
            .catch(error => {
                console.log(error);
            });
    },

    async getClubs({commit, rootState}) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.get('/user/getmyClubs')
            .then((resp) => {
                const data = resp.data
                commit('get_MyClubs', data)
            })
            .catch(error => {
                console.log(error);
            });
    },

    async getMyShootingbook({commit, rootState}) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.get('/user/getmyshootingbook')
            .then((resp) => {
                const data = resp.data
                commit('get_MyShootingbook', data)
            })
            .catch(error => {
                console.log(error);
            });
    },

    async send_ShootingBookRecord_to_confirmation({dispatch, rootState, commit}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.post('/user/shootingbook/submit_confirmation', Data)
            .then(() => {
                dispatch('getMyShootingbook');
                commit('SET_SHOOTINGBOOK_LOADER', false);
            })
            .catch(error => {
                swal.fire('Bestätigung einreichen', 'Es ist etwas schiefgelaufen beim einreichen', 'warning');
                console.log(error);
                commit('SET_SHOOTINGBOOK_LOADER', false);
            });

    },

    async getOpenShootingBookEntry({commit, rootState}) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.get('/user/recordShootingTime/getopenEntrys')
            .then((resp) => {
                const data = resp.data
                commit('get_OpenShootingBookEntry', data)
            })
            .catch(error => {
                console.log(error);
            });
    },

    async getOpenShootingBookData({commit, rootState}) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.get('/user/recordShootingTime')
            .then((resp) => {
                const data = resp.data
                commit('get_OpenShootingBookData', data)
            })
            .catch(error => {
                console.log(error);
            });
    },
    async getDisziWeapon({commit, rootState}, waffenID) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.get('/user/recordShootingTime/getDisziWaffe', {
            params: {
                waffenID
            }
        })
            .then((resp) => {
                const data = resp.data
                commit('get_Diszi_Weapons', data)
            })
            .catch(error => {
                console.log(error);
            });
    },

    async getAllDiszi({commit, rootState}) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.get('/user/weapons/Discipline')
            .then((resp) => {
                const data = resp.data
                commit('get_Diszi_All', data)
            })
            .catch(error => {
                console.log(error);
            });
    },

    // async getDisciplineSingelWeapon({commit, rootState}, waffenID){
    //     API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
    //     await API.get('/user/weapons/Discipline/show', {
    //         params: {
    //             waffenID
    //         }
    //     })
    //         .then((resp) => {
    //             const data = resp.data
    //             commit('get_Diszi_Single_Weapons', data)
    //         })
    //         .catch(error => {
    //             console.log(error);
    //         });
    // },

    async getShootingRangeFreeplaces({commit, rootState}, SRID) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        commit('SET_POPUP_RECORDSHOOTINGTIME_FREEPLACES_LOADER', true)
        await API.get('/user/shootinrange/getfreeplaces', {
            params: {
                shootingrange_id: SRID
            }
        })
            .then((resp) => {
                const data = resp.data
                commit('GET_SHOOTINGRANGEFREEPLACES', data)
                commit('SET_POPUP_RECORDSHOOTINGTIME_FREEPLACES_LOADER', false)
            })
            .catch(error => {
                console.log(error);
                commit('SET_POPUP_RECORDSHOOTINGTIME_FREEPLACES_LOADER', false)
            });
    },

    async setDisciplinWeapon({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.post('/user/weapons/Discipline/Discipline_add', Data)
            .then(() => {
                // dispatch('getDisciplineSingelWeapon', Data.waffenID);
                dispatch('getWeapons');
                dispatch('UserApp/getDashboard', null, {root: true})
            })
            .catch(error => {
                console.log(error);
            });

    },

    async delDisciplinWeapon({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.post('/user/weapons/Discipline/Discipline_del', Data)
            .then(() => {
                // dispatch('getDisciplineSingelWeapon', Data.waffenID);
                dispatch('getWeapons');
                dispatch('UserApp/getDashboard', null, {root: true})
            })
            .catch(error => {
                console.log(error);
                swal.fire('Waffe löschen', 'Es ist etwas schiefgelaufen beim löschen', 'warning');
            });

    },

    async setNewShootingBookRecord({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.post('/user/recordShootingTime', Data)
            .then(() => {
                dispatch('getOpenShootingBookEntry');
                dispatch('UserApp/getDashboard', null, {root: true})
                dispatch('UserApp/getMyShootingbook', null, {root: true})
                swal.fire('Schiessbuch', 'Dein Eintrag wurde erfolgreich erfasst', 'success');
            })
            .catch(error => {
                handleRegistrationError(error)
            });

    },

    async setNewExternalShootingBookRecord({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.post('/user/recordShootingTime_external', Data)
            .then(() => {
                // dispatch('getOpenShootingBookEntry');
                dispatch('UserApp/getDashboard', null, {root: true})
                dispatch('UserApp/getMyShootingbook', null, {root: true})
                swal.fire('Schiessbuch', 'Dein Eintrag wurde erfolgreich erfasst', 'success');
            })
            .catch(error => {
                handleRegistrationError(error)
            });

    },

    async sign_Shootingbook_entry({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.post('/user/shootingbook/sign', Data)
            .then(() => {
                dispatch('getMyShootingbook');
            })
            .catch(error => {
                const message = error.response.data.message
                swal.fire('Signieren', message, 'warning');
            });

    },

    async get_Shootingbook_sign({commit, rootState}, Data) {
        commit('CLEAR_SHOOTINGBOOK_SIGNATURE');
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.get('/user/shootingbook/show_sign', {
            params: Data
        })
            .then((resp) => {
                const data = resp.data
                commit('GET_SHOOTINGBOOK_SIGNATURE', data)
                commit('SET_SHOOTINGBOOK_LOADER', false)
            })
            .catch(error => {
                const message = error.response.data.message
                commit('SET_SHOOTINGBOOK_SIGN_ERROR', true);
                commit('SET_SHOOTINGBOOK_LOADER', false);
                swal.fire('Signieren', message, 'warning');
            });
    },


    async updateNewShootingBookRecord({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.post('/user/recordShootingTime/update', Data)
            .then(() => {
                dispatch('getOpenShootingBookEntry');
                dispatch('UserApp/getDashboard', null, {root: true})
                dispatch('UserApp/getMyShootingbook', null, {root: true})
            })
            .catch(error => {
                handleRegistrationError(error)
            });

    },

    async delShootingbookEntry({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.post('/user/recordShootingTime/delete', Data)
            .then(() => {
                dispatch('getOpenShootingBookEntry');
                dispatch('UserApp/getDashboard', null, {root: true})
                dispatch('UserApp/getMyShootingbook', null, {root: true})
                swal.fire('Eintrag gelöscht', 'Der Eintrag wurde erfolgreich gelöscht', 'success');
            })
            .catch(error => {
                swal.fire('Eintrag beenden', 'Es ist etwas schiefgelaufen beim beenden', 'warning');
                console.log(error)
            });
    },

    async setNewWeapon({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.post('/user/weapons/weapon_store', Data)
            .then(() => {
                dispatch('getWeapons');
                dispatch('UserApp/getDashboard', null, {root: true})
            })
            .catch(error => {
                handleRegistrationError(error)
            });

    },

    async delWeapon({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.get('/user/weapons/weapon_delete', {
            params: Data
        })
            .then((resp) => {
                dispatch('getWeapons');
                dispatch('UserApp/getDashboard', null, {root: true})
                swal.fire('Waffe löschen', resp.data, 'success');
            })
            .catch(error => {
                console.log(error);
                swal.fire('Waffe löschen', 'Es ist etwas schiefgelaufen beim löschen', 'warning');
            });

    },

    async setMyClub({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.post('/user/addClub', Data)
            .then(() => {
                dispatch('getClubs');
                dispatch('UserAuth/getUserProfilData', null, {root: true});
            })
            .catch(error => {
                console.log(error);
            });

    },

    async delMyClub({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.post('/user/delClub', Data)
            .then(() => {
                dispatch('getClubs');
                dispatch('UserAuth/getUserProfilData', null, {root: true});
            })
            .catch(error => {
                console.log(error);
            });

    },

    async setEducation({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.post('/user/education/education_add', Data)
            .then(() => {
                dispatch('getEduData');
            })
            .catch(error => {
                console.log(error);
            });

    },

    async delEducation({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.post('/user/education/education_del', Data)
            .then(() => {
                dispatch('getEduData');
            })
            .catch(error => {
                console.log(error);
            });

    },
    async getDashboard({commit, rootState}) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.get('/user/dashboard')
            .then((resp) => {
                const data = resp.data
                commit('GET_DASHBOARD', data)
            })
            .catch(error => {
                console.log(error);
            });
    },

    async getChartsData({commit, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.get('/user/analytics/weapon/Chart', {
            params: Data
        })
            .then((resp) => {
                const data = resp.data
                commit('GET_CHARTSDATA', data)
            })
            .catch(error => {
                console.log(error);
            });
    },
    async getShootingbookCountData({commit, rootState}) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.get('/user/analytics/shootingbook/getShootingbookCount')
            .then((resp) => {
                const data = resp.data
                commit('GET_SHOOTINGBOOK_COUNT', data)
            })
            .catch(error => {
                console.log(error);
            });
    },

    async getSelectedShootingRanges({commit, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.UserAuth.token}`
        await API.get('/user/recordShootingTime/getShootingRange', {
            params: Data
        })
            .then((resp) => {
                const data = resp.data.ClubRanges
                commit('SET_SELECTED_SHOOTINGRANGES', data)
            })
            .catch(error => {
                console.log(error);
                swal.fire('Schiessstände', 'Fehler in der Ermittlung der Schiessstände', 'warning');
            });
    },

    set_SelectedShootingRanges_to_Default({commit}) {
        commit('SET_SELECTED_SHOOTINGRANGES', []);
    },

    set_recordshootingTime_Freeplaces_PopUp({commit}, Data) {
        commit('SET_POPUP_RECORDSHOOTINGTIME_FREEPLACES', Data);
    }

};

export default actions;
