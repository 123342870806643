const getters = {
    weapon_Disciplines: (state) => (waffen_id) => {
        if (waffen_id > 0) {
            const Diszi = state.Weapons.find(wd => wd.id === waffen_id)
            return Diszi.disciplines;
        }else{
            return [];
        }
    },
    has_Education_Supervision: (state) => {
      const Education = state.Education.myEdu.find(es => es.can_Supervision === 1)
        if (typeof Education === 'undefined'){
            return false
        }else{
            return true
        }
    },
    is_Signature_Error: (state) => {
        return state.shootingbook_sign_error;
    },
    is_loading_shootingbook: (state) =>{
        return state.shootingbook_loader;
    },
    has_selected_ShootingRanges: (state) =>{
        return state.Selected_ShootingRanges;
    },
    is_open_popup_freeplaces:(state) =>{
        return state.recordshootingTime_show_freeplaces;
    },
    is_loading_freeplaces:(state) =>{
      return state.recordshootingTime_show_freeplaces_loader;
    }
};

export default getters;
