const state = {
    TextUwelcome: "",
    TextNewUser:"",
    TextUeberlassung:"",
    Education: {
        allEdu: [],
        myEdu: []
    },
    Weapons:[],
    DisziWeapon:[],
    Clubs:[],
    Selected_ShootingRanges:[],
    MyShootingbook:[],
    Shootingbook_Entry_Signature:[],
    OpenShootingBookEntry: [],
    OpenShootingBookData:[],
    Discipline:[],
    DisziSingleWeapon:[],
    ShootingRangeFreeplaces:[],
    Dashboard: {
        WeaponsCount: '',
        WeaponsTransferCount: '',
        WeaponsMyTransferCount: '',
        SBCount: '',
        SBOpenCount: '',
        SBLastEntry: [],
        WeeklyShootingCount: []
    },
    Charts:null,
    shootingbook_Count:null,
    shootingbook_sign_error: false,
    shootingbook_loader: false,
    recordshootingTime_show_freeplaces: false,
    recordshootingTime_show_freeplaces_loader: false
};

export default state;
