<template>
  <div class="container mt-4  px-5">
    <v-card>
      <v-card-title>
        Profil
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col>
            <v-text-field
                label="Name"
                v-model="clubprofil.name"
                placeholder="Name"
                outlined
            ></v-text-field>
          </v-col>

        </v-row>
        <v-row>
          <v-col>
            <v-text-field
                label="Email"
                v-model="clubprofil.email"
                placeholder="Email"
                outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                label="Vereinsnummer"
                v-model="clubprofil.Vereinsnummer"
                placeholder="Vereinsnummer"
                outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
                label="Telefon"
                v-model="clubprofil.telefon"
                placeholder="Telefon"
                outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                label="Strasse"
                v-model="clubprofil.strasse"
                placeholder="Strasse"
                outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
                label="Stadt"
                v-model="clubprofil.ort"
                placeholder="Stadt"
                outlined
            ></v-text-field>
          </v-col>
          <v-col>
            <v-text-field
                label="PLZ"
                v-model="clubprofil.PLZ"
                placeholder="PLZ"
                outlined
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-text-field
                label="URL für Heute"
                v-model="comp_Club_qrcode"
                placeholder="URL"
                append-icon="mdi-newspaper-plus"
                @click:append="generate_Code"
                readonly
                outlined
            ></v-text-field>
          </v-col>
          <v-col></v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col>
            <v-btn
                color="success"
                @click="save">
              Aktualisieren
            </v-btn>
          </v-col>
          <v-col>
            <v-btn
                outlined
                color="red darken-2">
              Profil löschen
            </v-btn>
          </v-col>
        </v-row>

      </v-card-actions>
    </v-card>
  </div>

</template>

<script>
export default {
  name: "profil",
  data: () => ({
    clubprofil:{
      name:'',
      strasse:'',
      telefon:'',
      PLZ:'',
      Vereinsnummer:'',
      email:'',
      ort:'',
    },
  }),
  computed: {
    comp_Club_qrcode(){
      var url = null;
      if (this.$store.state.ClubAuth.club_qrcode) {
        const clubid = this.$store.state.ClubAuth.club.id;
        const qrcode = this.$store.state.ClubAuth.club_qrcode
        url = this.$router.resolve({
          name: '/club/today_monitor',
          params: { clubid: clubid, token: qrcode }
        });
        url = process.env.VUE_APP_FRONTEND_URL + url.href;
      }
      return url
    },
   },
  mounted() {
    this.getClubProfil();
  },
  methods: {
    getClubProfil() {
      this.clubprofil = this.$store.state.ClubAuth.club;
    },
    save() {
      var Data = {
        inputName: this.clubprofil.name,
        inputTelefon: this.clubprofil.telefon,
        inputStrasse: this.clubprofil.strasse,
        inputVnr: this.clubprofil.Vereinsnummer,
        inputOrt: this.clubprofil.ort,
        inputPLZ: this.clubprofil.PLZ,
        inputEmail: this.clubprofil.email,
      };
      this.$store.dispatch('ClubAuth/setClubProfilData', Data)
    },
    generate_Code(){
      this.$store.dispatch('ClubAuth/generateQrCode');
    },

  },
}
</script>

<style scoped>

</style>
