<template>
  <div>
    <v-dialog
        v-model="comp_open_Popup"
        width="800px"
        height="300px">
      <v-card
      :loading="comp_loader_state">
        <template slot="progress">
          <v-progress-linear color="blue" indeterminate></v-progress-linear>
        </template>
        <v-card-title>
          Freie Bahn wählen
          <v-spacer></v-spacer>
          <v-btn icon @click="comp_open_Popup = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
            style="height:150px">
          <v-row
              class="mt-5">
            <v-btn
                v-for="n in comp_Freeplaces"
                :key="n.id"
                class="mx-1 my-1"
                @click="save_place(n.place)"
            >
              {{ n.place }}
            </v-btn>
          </v-row>
        </v-card-text>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    shootingrange_id: {
      type: Number,
    }
  },
  name: "freeplaces",
  data: () => ({}),
  computed: {
    comp_Freeplaces() {
      return this.$store.state.UserApp.ShootingRangeFreeplaces;
    },
    comp_open_Popup: {
      get(){
        return this.$store.getters['UserApp/is_open_popup_freeplaces'];
      },
      set(val){
        this.$store.dispatch('UserApp/set_recordshootingTime_Freeplaces_PopUp', val)
      }
    },
    comp_loader_state(){
      return this.$store.getters['UserApp/is_loading_freeplaces'];
    }
  },
  mounted() {
    this.load_freeplaces()
  },
  methods: {
    save_place(place) {
      this.$emit('close-dialog', place);
    },
    async load_freeplaces() {
      if (this.shootingrange_id !== undefined) {
        if (this.shootingrange_id > 0) {
          await this.$store.dispatch('UserApp/getShootingRangeFreeplaces', this.shootingrange_id)
        }
      }
    }


  },
}
</script>

<style scoped>

</style>