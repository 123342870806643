import {API} from "../../../axios";
import swal from "sweetalert2";
import {handleRegistrationError} from "@/plugins/store/errorHandlerSwal";

const actions = {

    login({dispatch, commit}, club) {

        return new Promise((resolve, reject) => {
            commit('auth_request')
            API.post('club/login', club)
                .then(resp => {

                    const token = resp.data.access_token
                    const club = resp.data.club
                    const clubmenu = resp.data.clubmenu
                    const club_qrcode = resp.data.qrcode

                    localStorage.setItem('token', token)
                    API.defaults.headers.common['Authorization'] = `Bearer ${token}`
                    commit('auth_success', {club, token, clubmenu, club_qrcode})
                    dispatch('ClubApp/getVwelcomeText', null, {root: true})
                    dispatch('ClubApp/getWeapons', null, {root: true})
                    resolve(resp)
                })
                .catch(err => {
                    // console.log(err)
                    swal.fire('Anmeldung!', err.response.data, 'warning');
                    commit('auth_error')
                    localStorage.removeItem('token')
                    reject(err)
                })
        })
    },

    logout({commit, state, rootState}) {
        return new Promise((resolve, reject) => {
            API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
            API.post('club/logout',
                {
                    id: state.club.id
                })
                .then(() => {

                    commit('logout')
                    commit('ClubApp/set_appDataClear', null, {root: true})
                    localStorage.removeItem('token')
                    delete API.defaults.headers.common['Authorization']
                    resolve()
                })
                .catch(err => {
                    // console.log(err)
                    commit('auth_error', err)
                    localStorage.removeItem('token')
                    reject(err)
                })

        })
    },
    async setClubProfilData({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.post('/club/profil/update', Data)
            .then(() => {
                dispatch('getClubProfilData');
            })
            .catch(error => {
                console.log(error);
            });

    },

    async setNewPassword({dispatch, rootState}, Data) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.post('/club/profil/changePassword', Data)
            .then(() => {
                dispatch('getClubProfilData');
                swal.fire('Passwort geändert', 'Dein Passwort wurde geändert', 'info');
            })
            .catch(error => {
                handleRegistrationError(error);
            });

    },
    async getClubProfilData({commit, rootState}) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.get('/club/profil')
            .then((resp) => {
                const club = resp.data.club
                commit('SET_PROFIL_DATA', {club})
            })
            .catch(error => {
                console.log(error);
            });
    },

    async registerClub({commit}, Data) {
        await API.post('/club/register', Data)
            .then((resp) => {
                const message = resp.data.message
                swal.fire('Profil angelegt', message, 'success');
                commit('REGISTER_CLUB')
            })
            .catch(error => {
                handleRegistrationError(error);
            });

    },

    async generateQrCode({commit, rootState}) {
        API.defaults.headers.common['Authorization'] = `Bearer ${rootState.ClubAuth.Clubtoken}`
        await API.get('/club/profil/QrCode/generate')
            .then((resp) => {
                console.log(resp);
                const club = resp.data
                commit('SET_CLUB_QRCODE', club)
            })
            .catch(error => {
                console.log(error);
            });
    },


};

export default actions;
