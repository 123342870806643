const mutations = {
    get_DSGVOText(state,data) {
        this.state.GeneralApp.TextDSGVO = data
    },
    get_StartseiteText(state,data) {
        this.state.GeneralApp.TextStartseite = data
    },
    GET_SEARCH_NAMES_TRANSFER(state, data){
        state.Weapon_Transfer_Search = data
    },
    SET_SHOW_NAVIGATION(state, data){
        state.Show_Navigation = data
    },
    SET_WEBSOCKET_STATE(state, data){
        state.websocket_online = data;
    },

};

export default mutations;
