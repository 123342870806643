
//Club Routen
const routes =  [
    {   path: '/club/home',
        name: '/club/home',
        component: () => import(/* webpackChunkName: "vwelcome" */ '../../../views/club/vwelcome'),
        meta: {requiresClubAuth: true, authLevels: [2]}
    },
    {   path: '/club/authorization',
        name: '/club/authorization',
        component: () => import(/* webpackChunkName: "authorization" */ '../../../views/club/authorization'),
        meta: {requiresClubAuth: true, authLevels: [2,3]}
    },
    {   path: '/club/shootingbook',
        name: '/club/shootingbook',
        component: () => import(/* webpackChunkName: "shootingbook" */ '../../../views/club/shootingbook'),
        meta: {requiresClubAuth: true, authLevels: [2,3]}
    },
    {   path: '/club/today',
        name: '/club/today',
        component: () => import(/* webpackChunkName: "today" */ '../../../views/club/today'),
        meta: {requiresClubAuth: true, authLevels: [2]}
    },
    {   path: '/club/today_monitor/:clubid/:token/:rangeid?',
        name: '/club/today_monitor',
        component: () => import(/* webpackChunkName: "today" */ '../../../views/club/today'),
        meta: {requiresClubAuth: true, authLevels: [0,2]}
    },
    {   path: '/club/profil',
        name: '/club/profil',
        component: () => import(/* webpackChunkName: "profil" */ '../../../views/club/profil'),
        meta: {requiresClubAuth: true, authLevels: [2]}
    },
    {   path: '/club/ranges',
        name: '/club/ranges',
        component: () => import(/* webpackChunkName: "ranges" */ '../../../views/club/ranges'),
        meta: {requiresClubAuth: true, authLevels: [2]}
    },
    {   path: '/club/verbaende',
        name: '/club/verbaende',
        component: () => import(/* webpackChunkName: "verbaende" */ '../../../views/club/verbaende'),
        meta: {requiresClubAuth: true, authLevels: [2]}
    },
    {   path: '/club/members',
        name: '/club/members',
        component: () => import(/* webpackChunkName: "members" */ '../../../views/club/members'),
        meta: {requiresClubAuth: true, authLevels: [2]}
    },
    {   path: '/club/workinghours',
        name: '/club/workinghours',
        component: () => import(/* webpackChunkName: "members" */ '../../../views/club/workinghours'),
        meta: {requiresClubAuth: true, authLevels: [2]}
    },
    {   path: '/club/changePassword',
        name: '/club/changePassword',
        component: () => import(/* webpackChunkName: "changePassword" */ '../../../views/club/changePassword'),
        meta: {requiresClubAuth: true, authLevels: [2]}
    },
    {   path: '/club/weapon',
        name: '/club/weapon',
        component: () => import(/* webpackChunkName: "weapon" */ '../../../views/club/weapon'),
        meta: {requiresClubAuth: true, authLevels: [2]}
    },
    {   path: '/club/terminal',
        name: '/club/terminal',
        component: () => import(/* webpackChunkName: "terminal" */ '../../../views/club/terminal'),
        meta: {requiresClubAuth: true, authLevels: [5]}
    }

];

export default routes
