<template>
  <div class="container">
    <div class="container register">
      <div class="row">
        <div class="col-md-3 register-left">
          <img
              src="/Bilder/book.png"
              alt=""
              class="rounded-circle">
          <h3>Herzlich Willkommen</h3>


        </div>
        <div class="col-md-9 register-right">
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
              <h3 class="register-heading">Profil erstellen</h3>
              <v-container>
                <v-form
                    class="register-form">
                  <v-row class="mb-5">
                    <v-col
                        cols="12"
                        sm="4"
                        md="4">
                      <v-checkbox
                          v-model="chooseLogin"
                          label="Benutzer"
                          color="success"
                          value="User"
                          hide-details
                      ></v-checkbox>
                    </v-col>
                    <v-col
                        cols="12"
                        sm="4"
                        md="4"></v-col>
                    <v-col
                        cols="12"
                        sm="4"
                        md="4">
                      <v-checkbox
                          v-model="chooseLogin"
                          label="Verein"
                          color="info"
                          value="Club"
                          hide-details
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                          v-model="email"
                          label="E-mail"
                          required
                          outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                          v-show="chooseLogin === 'User'"
                          v-model="vorname"
                          label="Vorname"
                          required
                          outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                          v-model="name"
                          label="Name"
                          required
                          outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                          v-show="chooseLogin === 'User'"
                          v-model="mitgliedsnummer"
                          label="Mitgliedsnummer"
                          required
                          outlined
                      ></v-text-field>
                      <v-text-field
                          v-show="chooseLogin === 'Club'"
                          v-model="vereinsnr"
                          label="Vereinsnummer"
                          required
                          outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-text-field
                          v-model="password"
                          name="input-10-1"
                          hint=""
                          label="Passwort"
                          :rules="[rules.required, rules.min, rules.strength]"
                          :type="showPW1 ? 'text' : 'password'"
                          required
                          outlined
                      ></v-text-field>
                      <v-progress-linear
                          :background-opacity="opacity"
                          :color="score.color"
                          :value="score.value"
                      ></v-progress-linear>
                    </v-col>
                    <v-col>
                      <v-text-field
                          v-model="password1"
                          name="input-10-1"
                          hint=""
                          label="Passwort wiederholen"
                          :rules="[rules.required, passwordConfirmationRule]"
                          :type="showPW2 ? 'text' : 'password'"
                          required
                          outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <v-btn
                          color="primary"
                          :disabled="!verified"
                          @click="save"
                      >
                        Registrieren
                      </v-btn>
                    </v-col>
                    <v-col>
                      <vue-hcaptcha
                          sitekey="c54eaec0-f39c-4d44-9388-c74ea76c22ec"
                          @verify="onVerify"
                          @expired="onExpire"
                          @challengeExpired="onChallengeExpire"
                          @error="onError"
                      />
                    </v-col>

                  </v-row>

                </v-form>
              </v-container>
            </div>

          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import swal from "sweetalert2";
import zxcvbn from 'zxcvbn';

export default {
  name: "register",
  data() {
    return {
      email: "",
      chooseLogin: 'User',
      password: "",
      password1: "",
      vorname: "",
      name: "",
      mitgliedsnummer: "",
      vereinsnr: "",
      showPW1: false,
      showPW2: false,
      opacity: null,
      rules: {
        required: value => !!value || 'Passwort eingeben',
        min: v => v.length >= 10 || 'Das Passwort muss mindestens 10 Zeichen lang sein.',
        strength: v => zxcvbn(v).score >= 3 || 'Ein stärkeres Passwort wählen! Es müssen Zeichen, Zahlen sowie Sonderzeichen vorhanden sein!',
      },
      verified: false,
      expired: false,
      token: null,
      eKey: null,
      error: null,
    }
  },
  computed: {
    passwordConfirmationRule() {
      return () => (this.password === this.password1) || 'Die Passwörter stimmen nicht überein!'
    },
    score() {
      const result = zxcvbn(this.password);

      switch (result.score) {
        case 4:
          return {
            color: "light-blue",
            value: 100
          };
        case 3:
          return {
            color: "light-green",
            value: 75
          };
        case 2:
          return {
            color: "yellow",
            value: 50
          };
        case 1:
          return {
            color: "orange",
            value: 25
          };
        default:
          return {
            color: "red",
            value: 0
          };
      }
    }
  },
  methods: {
    save() {
      if (!this.verified) {
        swal.fire('Captcha', 'Captcha nicht gelöst!', 'warning');
        return;
      }

      var Data = {};

      if (this.chooseLogin === 'User') {

        Data = {
          email: this.email,
          password: this.password,
          vorname: this.vorname,
          name: this.name,
          mitgliedsnr: this.mitgliedsnummer,
          hcaptcha_token: this.token
        }
        this.$store.dispatch('UserAuth/registerUser', Data)
            .then(() => this.$router.push('/login'))

      } else if (this.chooseLogin === 'Club') {

        Data = {
          email: this.email,
          password: this.password,
          name: this.name,
          vereinsnr: this.vereinsnr,
          hcaptcha_token: this.token
        }
        this.$store.dispatch('ClubAuth/registerClub', Data)
            .then(() => this.$router.push('/login'))

      }
    },
    onVerify(token, ekey) {
      this.verified = true;
      this.token = token;
      this.eKey = ekey;
    },
    onExpire() {
      this.verified = false;
      this.token = null;
      this.eKey = null;
      this.expired = true;
    },
    onChallengeExpire() {
      this.verified = false;
      this.token = null;
      this.eKey = null;
      this.expired = true;
    },
    onError(err) {
      this.token = null;
      this.eKey = null;
      this.error = err;
    },
  },
}
</script>

<style media="screen">
.register {
  background: -webkit-linear-gradient(left, #32Cd32, #a4f9a4);
  margin-top: 3%;
  padding: 3%;
}

.register-left {
  text-align: center;
  color: #fff;
  margin-top: 4%;
}

.register-left input {
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  width: 60%;
  background: #f8f9fa;
  font-weight: bold;
  color: #383d41;
  margin-top: 30%;
  margin-bottom: 3%;
  cursor: pointer;
}

.register-right {
  background: #f8f9fa;
  border-top-left-radius: 10% 50%;
  border-bottom-left-radius: 10% 50%;
}

.register-left img {
  margin-top: 15%;
  margin-bottom: 5%;
  width: 25%;
  -webkit-animation: mover 2s infinite alternate;
  animation: mover 1s infinite alternate;
}

@-webkit-keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

@keyframes mover {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-20px);
  }
}

.register-left p {
  font-weight: lighter;
  padding: 12%;
  margin-top: -9%;
}

.register .register-form {
  padding: 10%;
  margin-top: 10%;
}

.btnRegister {
  float: right;
  margin-top: 10%;
  border: none;
  border-radius: 1.5rem;
  padding: 2%;
  background: #0062cc;
  color: #fff;
  font-weight: 600;
  width: 50%;
  cursor: pointer;
}

.register .nav-tabs {
  margin-top: 3%;
  border: none;
  background: #0062cc;
  border-radius: 1.5rem;
  width: 28%;
  float: right;
}

.register .nav-tabs .nav-link {
  padding: 2%;
  height: 34px;
  font-weight: 600;
  color: #fff;
  border-top-right-radius: 1.5rem;
  border-bottom-right-radius: 1.5rem;
}

.register .nav-tabs .nav-link:hover {
  border: none;
}

.register .nav-tabs .nav-link.active {
  width: 100px;
  color: #0062cc;
  border: 2px solid #0062cc;
  border-top-left-radius: 1.5rem;
  border-bottom-left-radius: 1.5rem;
}

.register-heading {
  text-align: center;
  margin-top: 8%;
  margin-bottom: -15%;
  color: #495057;
}
</style>
