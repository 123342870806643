<template>
  <div class="mt-4  px-5">
    <v-alert
        v-show="!comp_OpenShootingBookData_CountOwnWeapons"
        color="red"
        type="error">
      Du hast noch keine Waffe hinterlegt, bitte gehe auf <a href="/user/weapon">Meine Waffen</a>
      und füge zuerst deine Waffen hinzu!
    </v-alert>
    <v-alert
        v-show="comp_OpenShootingBookData_Club_DefaultClub"
        color="red"
        type="error">
      Du hast keinen Standardverein festgelegt, bitte stelle auf deinem <a href="/user/profil">Profil</a>
      zuerst deinen Verein ein, bzw. lasse ihn vom Verein bestätigen!
    </v-alert>

    <v-card
        class="mt-4 mb-4"
        v-if="this.comp_OpenShootingBookData_CountOwnWeapons > 0"
    >
      <v-card-title>Neuer Eintrag im schuetzenbuch</v-card-title>
      <v-divider></v-divider>
      <v-tabs
          v-model="tab"
          background-color="success accent-4"
          center-active
          dark
      >
        <v-tab href="#internal">schuetzenbuch Eintrag</v-tab>
        <v-tab href="#external">Externer Eintrag</v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item value="internal"
                      v-if="!comp_OpenShootingBookData_Club_DefaultClub & this.comp_OpenShootingBookData_CountOwnWeapons > 0">

            <recordshootingtime_form_internaly/>

          </v-tab-item>
          <v-tab-item value="external">

            <recordshootingtime_form_externaly/>

          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>

    <OpenShootingBookEntry></OpenShootingBookEntry>
  </div>
</template>

<script>
import OpenShootingBookEntry from '../component/OpenShootingBookEntry';
import recordshootingtime_form_internaly from "@/views/component/recordshootingtime_form_internaly.vue";
import recordshootingtime_form_externaly from "@/views/component/recordshootingtime_form_externaly.vue";

export default {
  name: "recordtime",
  components: {OpenShootingBookEntry, recordshootingtime_form_internaly, recordshootingtime_form_externaly},
  data: () => ({
    menu: false,
    menu2: false,
    tab: null
  }),
  computed: {
    comp_OpenShootingBookData_Club_DefaultClub() {
      const state = this.$store.state.UserApp.OpenShootingBookData;
      var data = false;
      if (state != null && Object.keys(state).length > 0) {
        data = state.Club.Vereinsnummer === '99999';
      }
      return data;
    },
    comp_OpenShootingBookData_CountOwnWeapons() {
      return this.$store.state.UserApp.Weapons.length;
    },
  },
  beforeMount() {
    this.initData();
  },
  methods: {
    initData() {
      if (!this.comp_OpenShootingBookData_Club_DefaultClub && this.comp_OpenShootingBookData_CountOwnWeapons > 0) {
        this.$store.dispatch('UserApp/set_SelectedShootingRanges_to_Default')
      }
    },
  },
}
</script>

<style scoped>

</style>
