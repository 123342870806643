import swal from "sweetalert2";
export function handleRegistrationError(error) {
    console.log(error);

    if (error.response && error.response.data && error.response.data.errors) {
        const validationMessages = error.response.data.errors;

        let errorList = '<ul>';
        for (let field in validationMessages) {
            if (Object.prototype.hasOwnProperty.call(validationMessages, field)) {
                validationMessages[field].forEach(msg => {
                    errorList += `<li>${msg}</li>`;
                });
            }
        }
        errorList += '</ul>';

        swal.fire({
            title: 'Fehler',
            html: errorList,
            icon: 'warning'
        });
    } else {
        console.error(error);
        swal.fire('Fehler', 'Es ist ein allgemeiner Fehler aufgetreten.', 'error');
    }
}
