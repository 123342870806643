<template>
  <v-card>
    <v-form ref="form_internaly" v-on:submit.prevent>
      <v-card-text>
        <v-container>
          <freeplaces
              v-if="comp_open_Popup"
              :shootingrange_id="newShootingBook.ClubRange.id"
              :retain-focus="false"
              @close-dialog="closeDialog"/>
          <v-row>
            <v-col>
              <v-select
                  v-model="newShootingBook.Verein"
                  :items="comp_meine_Vereine"
                  item-text="name"
                  item-value="id"
                  label="Vereine"
                  value=""
                  return-object
                  outlined
                  class="mx-1"
                  :retain-focus="false"
                  @change="verein_select"
                  :rules="[rules.required]"
                  :error-messages="errorMessages"
              >
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ data.item.Vereinsnummer }} {{ data.item.name }}
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.Vereinsnummer }} {{ data.item.name }}
                </template>

              </v-select>
            </v-col>
            <v-col>
              <v-select
                  v-model="comp_OpenShootingBookData_defaultClubRanges"
                  :items="comp_selected_Shootingranges"
                  item-text="Stand"
                  item-value="id"
                  label="Stand"
                  value=""
                  return-object
                  no-data-text="Kein Stand auswählbar - zuerst Verein wählen!"
                  outlined
                  class="mx-1"
                  :disabled="disabledSelectsSL"
                  :rules="[rules.dynamicValidation_User_SA()]"
                  :error-messages="errorMessages"
              >
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ data.item.LangBez }}
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.LangBez }}
                </template>

              </v-select>

            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                  v-model="newShootingBook.aktivitaet"
                  :items="comp_OpenShootingBookData_Trainingart"
                  item-text="Aktivität"
                  item-value="id"
                  label="Aktivität"
                  value=""
                  return-object
                  outlined
                  class="mx-1"
                  :disabled="disabledSelects"
              >
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ data.item.aktivitaet }}
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.aktivitaet }}
                </template>

              </v-select>
            </v-col>
            <v-col>
              <v-select
                  v-model="newShootingBook.shootingAction"
                  :items="comp_Shootingbook_Action"
                  item-text="Funktion"
                  item-value="value"
                  label="Funktion"
                  value=""
                  @change="set_Options"
                  return-object
                  outlined
                  class="mx-1"
              >
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ data.item.function }}
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.function }}
                </template>

              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="newShootingBook.Place"
                  label="Bahn"
                  @click="comp_open_Popup = true"
                  outlined
                  :disabled="disabledSelects"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                  v-model="newShootingBook.OwnWeapon"
                  :items="comp_OpenShootingBookData_OwnWeapons"
                  item-text="Waffe"
                  item-value="id"
                  label="Waffe"
                  value=""
                  return-object
                  outlined
                  class="mx-1"
                  :disabled="disabledSelects"
              >
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ data.item.KurzT }}
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.KurzT }}
                </template>

              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                  v-model="newShootingBook.Disziplin"
                  :items="comp_OpenShootingBookData_DisziWeapon"
                  item-text="Disziplin"
                  item-value="id"
                  label="Disziplin"
                  value=""
                  return-object
                  outlined
                  class="mx-1"
                  :retain-focus="false"
                  :disabled="disabledSelects"
                  :rules="[rules.dynamicValidation_User()]"
                  :error-messages="errorMessages"
              >
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ data.item.Nummer }} {{ data.item.LangBez }}
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.Nummer }} {{ data.item.LangBez }}
                </template>

              </v-select>
            </v-col>
            <v-col>
              <v-datetime-picker
                  label="Begin"
                  date-format="dd.MM.yyyy"
                  time-format="HH:mm"
                  v-model="newShootingBook.Begin"
                  :text-field-props="textFieldProps"
                  :time-picker-props="timePickerProps"
                  :date-picker-props="datePickerProps"
                  :retain-focus="false"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock-outline</v-icon>
                </template>
              </v-datetime-picker>

            </v-col>
          </v-row>
        </v-container>

      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="success justify-right" text @click="save()">
          <v-icon class="mr-2">
            far fa-save
          </v-icon>
          sichern
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import freeplaces from "@/views/component/freeplaces.vue";

export default {
  name: "recordshootingtime_form_internaly",
  components: {freeplaces},
  data: () => ({
    disabledSelects: false,
    disabledSelectsSL: false,
    newShootingBook: {
      shootingAction: {function: 'Schütze', value: 1},
      aktivitaet: {id: 1},
      ClubRange: {},
      OwnWeapon: {},
      Disziplin: {},
      Begin: new Date(),
      Place: null,
      Verein: null,
    },
    WeaponDiszi: [],
    textFieldProps: {
      outlined: true,
    },
    datePickerProps: {},
    timePickerProps: {
      format: "24hr"
    },
    signature_style: {border: 'black 3px solid'},
    rules: {
      required: value => !!value || 'Pflichtfeld.',
      dynamicValidation_User_SA(value) {
        const shootingActionValue = this.newShootingBook?.shootingAction?.value;
        if (shootingActionValue === 1 || shootingActionValue === 2) {
          return value && Object.keys(value).length > 0 ? true : 'Feld darf nicht leer sein.';
        }
        // Andere Funktionen benötigen keine Validierung
        return true;
      },
      dynamicValidation_User(value) {
        const shootingActionValue = this.newShootingBook?.shootingAction?.value;
        if (shootingActionValue === 1) {
          return value && Object.keys(value).length > 0 ? true : 'Feld darf nicht leer sein.';
        }
        // Andere Funktionen benötigen keine Validierung
        return true;
      },
    },
    formHasErrors: false,
    errorMessages: ''
  }),
  computed: {
    comp_OpenShootingBookData_Club_DefaultClub() {
      const state = this.$store.state.UserApp.OpenShootingBookData;
      var data = false;
      if (state != null && Object.keys(state).length > 0) {
        data = state.Club.Vereinsnummer === '99999';
      }
      return data;
    },
    comp_OpenShootingBookData_defaultClubRanges: {
      get() {
        return this.$store.state.UserApp.OpenShootingBookData.ClubRanges[0];
      },
      set(val) {
        this.newShootingBook.ClubRange = val;
      },
    },
    comp_open_Popup: {
      get() {
        return this.$store.getters['UserApp/is_open_popup_freeplaces'];
      },
      set(val) {
        this.$store.dispatch('UserApp/set_recordshootingTime_Freeplaces_PopUp', val)
      }
    },

    comp_selected_Shootingranges() {
      return this.$store.getters['UserApp/has_selected_ShootingRanges'];
    },

    comp_OpenShootingBookData_OwnWeapons() {
      return this.$store.state.UserApp.Weapons;
    },
    comp_OpenShootingBookData_CountOwnWeapons() {
      return this.$store.state.UserApp.Weapons.length;
    },
    comp_OpenShootingBookData_Trainingart() {
      return this.$store.state.UserApp.OpenShootingBookData.trainingart;
    },
    comp_OpenShootingBookData_DisziWeapon() {
      let Data = this.$store.getters['UserApp/weapon_Disciplines'](this.newShootingBook.OwnWeapon.id)
      if (typeof Data === 'undefined' || Data.length === 0) {
        Data = this.$store.state.UserApp.Discipline;
      }
      return Data
    },
    comp_Shootingbook_Action() {
      let Data = [
        {function: 'Schütze', value: 1},
      ]
      if (this.$store.getters['UserApp/has_Education_Supervision']) {
        Data.push(
            {function: 'Standaufsicht', value: 2},
            {function: 'Schießsportleiter', value: 3}
        )
      }
      return Data
    },
    comp_meine_Vereine() {
      return this.$store.state.UserApp.Clubs.meineVereine
    },
    comp_default_Verein() {
      const defVerein = this.$store.state.UserApp.Clubs.defVerein;
      if (Array.isArray(defVerein) && defVerein.length > 0) {
        return this.comp_meine_Vereine.find(v => v.id === defVerein[0].defaultVerein);
      }
      return null;
    }
  },

  beforeMount() {
    this.initData();
  },

  created() {
    // Initialisiere Verein mit der Default-Einstellung
    this.newShootingBook.Verein = this.comp_default_Verein || null;
    this.verein_select();
  },

  methods: {
    initData() {
      if (!this.comp_OpenShootingBookData_Club_DefaultClub && this.comp_OpenShootingBookData_CountOwnWeapons > 0) {
        this.newShootingBook.OwnWeapon = this.$store.state.UserApp.Weapons[0];
        this.newShootingBook.ClubRange = {};
        this.$store.dispatch('UserApp/set_SelectedShootingRanges_to_Default')
      }
    },

    async save() {
      if (!this.newShootingBook.Verein) {
        this.errorMessages = 'Verein ist ein Pflichtfeld!';
        return;
      }

      if (this.$refs.form_internaly.validate()) {

        var Data;
        switch (this.newShootingBook.shootingAction.value) {
          case 1:

            if (
                Object.keys(this.newShootingBook.Disziplin).length === 0 ||
                Object.keys(this.newShootingBook.ClubRange).length === 0 ||
                Object.keys(this.newShootingBook.Verein).length === 0
            ) {
              this.errorMessages = 'Pflichtfelder ausfüllen!';
              return;
            }

            Data = {
              inputVereinID: this.newShootingBook.Verein.id,
              inputStand: this.newShootingBook.ClubRange.id,
              inputTrainingArt: this.newShootingBook.aktivitaet.id,
              inputDisz: this.newShootingBook.Disziplin.id,
              inputWaffe: this.newShootingBook.OwnWeapon.id,
              inputPlatz: this.newShootingBook.Place,
              inputSA: this.newShootingBook.shootingAction.value,
              inputBegin: this.newShootingBook.Begin,
            };
            break;
          case 2:

            if (
                Object.keys(this.newShootingBook.ClubRange).length === 0 ||
                Object.keys(this.newShootingBook.Verein).length === 0
            ) {
              this.errorMessages = 'Pflichtfelder ausfüllen!';
              return;
            }

            Data = {
              inputVereinID: this.newShootingBook.Verein.id,
              inputStand: this.newShootingBook.ClubRange.id,
              inputSA: this.newShootingBook.shootingAction.value,
              inputBegin: this.newShootingBook.Begin,
            };
            break;
          case 3:

            if (Object.keys(this.newShootingBook.Verein).length === 0) {
              this.errorMessages = 'Pflichtfelder ausfüllen!';
              return;
            }

            Data = {
              inputVereinID: this.newShootingBook.Verein.id,
              inputSA: this.newShootingBook.shootingAction.value,
              inputBegin: this.newShootingBook.Begin,
            };
            break;
        }

        await this.$store.dispatch('UserApp/setNewShootingBookRecord', Data)
            .then(this.set_defaultInputs())

      }
    },
    set_Options() {
      switch (this.newShootingBook.shootingAction.value) {
        case 1:
          this.disabledSelects = false
          this.disabledSelectsSL = false
          break;
        case 2:
          this.disabledSelects = true
          break;
        case 3:
          this.disabledSelects = true
          this.disabledSelectsSL = true
          break;
      }
    },
    async set_defaultInputs() {
      this.newShootingBook = {
        shootingAction: {function: 'Schütze', value: 1},
        aktivitaet: {id: 1},
        ClubRange: {},
        OwnWeapon: this.$store.state.UserApp.Weapons[0],
        Disziplin: {},
        Begin: new Date(),
        Place: null,
        Verein: this.comp_default_Verein || null
      }
      this.$store.dispatch('UserApp/set_SelectedShootingRanges_to_Default')
      await this.verein_select()
    },

    async verein_select() {
      const Data = {
        club_id: this.newShootingBook.Verein.id
      }
      await this.$store.dispatch('UserApp/getSelectedShootingRanges', Data);

      const ranges = this.comp_selected_Shootingranges;
      if (ranges && ranges.length > 0) {
        this.newShootingBook.ClubRange = ranges[0];
      } else {
        this.newShootingBook.ClubRange = {};
      }

      const disziplinen = this.comp_OpenShootingBookData_DisziWeapon;
      if (disziplinen && disziplinen.length > 0) {
        this.newShootingBook.Disziplin = disziplinen[0];
      } else {
        this.newShootingBook.Disziplin = {};
      }
    },

    closeDialog(place) {
      this.comp_open_Popup = false;
      this.newShootingBook.Place = place;
    },
  },

}
</script>

<style scoped>

</style>