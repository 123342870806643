<template>
  <div class="row">
    <div class="col-sm h-50">
      <v-card
          class="border-info bg-info"
          style="width: 18rem; height: 300px ;"
          color="info"
          @click="router().push('/user/weapon')">
        <v-img
            :src="require('@/assets/Bilder/dashboard.jpg')"
            class="img-fluid"
        >
          <v-card-title class="white--text mt-8">
            <v-avatar size="56">
              <img
                  :src="require('@/assets/Bilder/gun.png')"
              >
            </v-avatar>
            <p class="ml-3">
              Meine Waffen
            </p>

          </v-card-title>
        </v-img>

        <v-card-text class="white--text mt-0">
          Du hast bis jetzt <b>{{ comp_WeaponCount }}</b> in deinem Account
          <br>
          <span
              v-if="comp_WeaponTransferCount != false"
              class="mt-2"
          >
            Du hast <b>{{ comp_WeaponTransferCount }}</b>
          </span>
          <br>
          <span
              v-if="comp_WeaponMyTransferCount != false"
              class="mt-2"
          >
            Du hast <b>{{ comp_WeaponMyTransferCount }}</b>
          </span>
        </v-card-text>
      </v-card>
    </div>

    <div class="col-sm h-50">
      <v-card
          class="bg-primary border-primary"
          style="width: 18rem; height: 300px ;"
          color="primary"
          @click="router().push('/user/shootingbook')">
        <v-img
            :src="require('@/assets/Bilder/dashboard.jpg')"
        >
          <v-card-title class="white--text mt-8">
            <v-avatar size="56">
              <img
                  :src="require('@/assets/Bilder/zeugnis-100.png')"
              >
            </v-avatar>
            <p class="ml-3">
              letzter Eintrag
            </p>

          </v-card-title>
        </v-img>

        <v-card-text class="white--text mt-2">
          <table class="table">
            <tr>
              <th>Anfang</th>
              <td>{{ comp_SBLast_Begin }}
              </td>
            </tr>
            <tr>
              <th>Ende</th>
              <td>{{ comp_SBLast_End }}
              </td>
            </tr>
            <tr>
              <th>Waffe</th>
              <td>{{ comp_SBLast_Weapon }}</td>
            </tr>
            <tr>
              <th>Ergebniss</th>
              <td>{{ comp_SBLast_Ergebniss }}</td>
            </tr>
          </table>
        </v-card-text>
      </v-card>
    </div>

    <div class="col-sm h-50">
      <v-card
          class="bg-warning border-warning"
          style="width: 18rem; height: 300px ;"
          color="warning"
          @click="router().push('/user/shootingbook')">
        <v-img
            :src="require('@/assets/Bilder/dashboard.jpg')"
        >
          <v-card-title class="white--text mt-8">
            <v-avatar size="56">
              <img
                  :src="require('@/assets/Bilder/add-property-1.png')"
              >
            </v-avatar>
            <p class="ml-3">
              Schiessbuch
            </p>

          </v-card-title>
        </v-img>

        <v-card-text class="white--text mt-2">
          Du hast <b>{{ comp_SbCount }}</b> in deinem Schiessbuch
          <br>
          Davon {{ comp_SbOpenCount }} noch nicht abgeschlossen
        </v-card-text>
      </v-card>
    </div>

  </div>
</template>

<script>
import router from "@/plugins/router";

export default {
  name: 'dashboard',
  data: () => ({}),
  computed: {
    comp_WeaponCount() {
      let data = '';
      const state = this.$store.state.UserApp.Dashboard.WeaponsCount;
      if (state === 1) {
        data = state + ' Waffe';
      } else {
        data = state + ' Waffen';
      }
      return data
    },
    comp_WeaponTransferCount() { // Die habe ich überlassen bekommen
      let data = false;
      const state = this.$store.state.UserApp.Dashboard.WeaponsTransferCount;
      if (state === 1) {
        data = state + ' überlassene Waffe';
      } else if (state > 1) {
        data = state + ' überlassene Waffen';
      }
      return data
    },
    comp_WeaponMyTransferCount() { // Die habe ich an überlassen
      let data = false;
      const state = this.$store.state.UserApp.Dashboard.WeaponsMyTransferCount;
      if (state === 1) {
        data = state + ' Waffe überlassen';
      } else if (state > 1) {
        data = state + ' Waffen überlassen';
      }
      return data
    },
    comp_SbCount() {
      let data = '';
      const state = this.$store.state.UserApp.Dashboard.SBCount;
      if (state === 1) {
        data = state + ' Eintrag';
      } else {
        data = state + ' Einträge';
      }
      return data
    },
    comp_SbOpenCount() {
      return this.$store.state.UserApp.Dashboard.SBOpenCount;
    },
    comp_SBLast_present() {
      let data = false;
      const state = this.$store.state.UserApp.Dashboard.SBLastEntry;
      if (state != null && Object.keys(state).length > 0) {
        data = true;
      }
      return data;
    },
    comp_SBLast_Begin() {
      let data = null;
      if (this.comp_SBLast_present) {
        const state = this.$store.state.UserApp.Dashboard.SBLastEntry.beginn;

        if (state !== null && state !== 'undefined') {
          data = new Date(state.replace(" ", "T")).toLocaleString([], {dateStyle: 'short', timeStyle: 'short'})
        }
      }
      return data;
    },
    comp_SBLast_End() {
      let data = null;
      if (this.comp_SBLast_present) {
        const state = this.$store.state.UserApp.Dashboard.SBLastEntry.ende;

        if (state !== null && state !== 'undefined') {
          data = new Date(state.replace(" ", "T")).toLocaleString([], {dateStyle: 'short', timeStyle: 'short'})
        }
      }
      return data;
    },
    comp_SBLast_Weapon() {
      let data = '';
      if (this.comp_SBLast_present) {
        data = this.$store.state.UserApp.Dashboard.SBLastEntry.waffe;
      }
      return data
    },
    comp_SBLast_Ergebniss() {
      let data = '';
      if (this.comp_SBLast_present) {
        data = this.$store.state.UserApp.Dashboard.SBLastEntry.trergges;
      }
      return data
    }
  },
  beforeMount() {
    this.get_DashboardData();
  },
  methods: {
    router() {
      return router
    },
    get_DashboardData() {
      this.$store.dispatch('UserApp/getDashboard')
    },
  },
}

</script>

<style scoped>

</style>