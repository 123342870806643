<template>
  <v-card>
    <v-form ref="form_internaly" v-on:submit.prevent>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-text-field
                  label="Verein"
                  v-model="external_club_name"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                  v-model="newShootingBook.aktivitaet"
                  :items="comp_OpenShootingBookData_Trainingart"
                  item-text="Aktivität"
                  item-value="id"
                  label="Aktivität"
                  value=""
                  return-object
                  outlined
                  class="mx-1"
                  :disabled="disabledSelects"
                  :rules="[rules.required]"
                  :error-messages="errorMessages"
              >
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ data.item.aktivitaet }}
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.aktivitaet }}
                </template>

              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  v-model="newShootingBook.Place"
                  label="Bahn"
                  outlined
              ></v-text-field>
            </v-col>
            <v-col>
              <v-select
                  v-model="newShootingBook.OwnWeapon"
                  :items="comp_OpenShootingBookData_OwnWeapons"
                  item-text="Waffe"
                  item-value="id"
                  label="Waffe"
                  value=""
                  return-object
                  outlined
                  class="mx-1"
                  :disabled="disabledSelects"
              >
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ data.item.KurzT }}
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.KurzT }}
                </template>

              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                  v-model="newShootingBook.Disziplin"
                  :items="comp_OpenShootingBookData_DisziWeapon"
                  item-text="Disziplin"
                  item-value="id"
                  label="Disziplin"
                  value=""
                  return-object
                  outlined
                  class="mx-1"
                  :retain-focus="false"
                  :disabled="disabledSelects"
              >
                <template slot="selection" slot-scope="data">
                  <!-- HTML that describe how select should render selected items -->
                  {{ data.item.Nummer }} {{ data.item.LangBez }}
                </template>
                <template slot="item" slot-scope="data">
                  <!-- HTML that describe how select should render items when the select is open -->
                  {{ data.item.Nummer }} {{ data.item.LangBez }}
                </template>

              </v-select>
            </v-col>
            <v-col>
              <v-datetime-picker
                  label="Datum"
                  date-format="dd.MM.yyyy"
                  time-format="HH:mm"
                  v-model="newShootingBook.Begin"
                  :text-field-props="textFieldProps"
                  :time-picker-props="timePickerProps"
                  :date-picker-props="datePickerProps"
                  :retain-focus="false"
              >
                <template slot="dateIcon">
                  <v-icon>mdi-calendar</v-icon>
                </template>
                <template slot="timeIcon">
                  <v-icon>mdi-clock-outline</v-icon>
                </template>
              </v-datetime-picker>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                  label="Schuss"
                  v-model="external_Schuss"
                  outlined
                  type="number"
                  min="1"
                  max="5000"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                  label="Ergebniss"
                  v-model="external_TrGes"
                  outlined
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-container>

      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn class="success justify-right" text @click="save_externaL_shootingbook()">
          <v-icon class="mr-2">
            far fa-save
          </v-icon>
          sichern
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
export default {
  name: "recordshootingtime_form_externaly",
  data: () => ({
    menu: false,
    menu2: false,
    tab: null,
    external_club_name: '',
    external_TrGes: '',
    external_Schuss: '',
    disabledSelects: false,
    disabledSelectsSL: false,
    newShootingBook: {
      shootingAction: {function: 'Schütze', value: 1},
      aktivitaet: {id: 1},
      ClubRange: {},
      OwnWeapon: {},
      Disziplin: {},
      Begin: new Date(),
      Place: null,
      Verein: null,
    },
    WeaponDiszi: [],
    textFieldProps: {
      outlined: true,
    },
    datePickerProps: {},
    timePickerProps: {
      format: "24hr"
    },
    signature_style: {border: 'black 3px solid'},
    rules: {
      required: value => !!value || 'Pflichtfeld.',
      counter: value => value.length <= 200 || 'Max 200 Zeichen',
      email: value => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return pattern.test(value) || 'Ungültige E-Mail.'
      },
    },
    formHasErrors: false,
    errorMessages: ''
  }),
  computed: {
    comp_OpenShootingBookData_OwnWeapons() {
      return this.$store.state.UserApp.Weapons;
    },
    comp_OpenShootingBookData_CountOwnWeapons() {
      return this.$store.state.UserApp.Weapons.length;
    },
    comp_OpenShootingBookData_Trainingart() {
      return this.$store.state.UserApp.OpenShootingBookData.trainingart;
    },
    comp_OpenShootingBookData_DisziWeapon() {
      let Data = this.$store.getters['UserApp/weapon_Disciplines'](this.newShootingBook.OwnWeapon.id)
      if (typeof Data === 'undefined' || Data.length === 0) {
        Data = this.$store.state.UserApp.Discipline;
      }
      return Data
    },
  },

  created() {
    this.on_created()
  },

  methods: {

    on_created() {
      this.newShootingBook.OwnWeapon = this.$store.state.UserApp.Weapons[0];

      const disziplinen = this.comp_OpenShootingBookData_DisziWeapon;
      if (disziplinen && disziplinen.length > 0) {
        this.newShootingBook.Disziplin = disziplinen[0];
      } else {
        this.newShootingBook.Disziplin = {};
      }
    },

    async save_externaL_shootingbook() {
      let Data = {
        ext_clubname: this.external_club_name,
        inputTrainingArt: this.newShootingBook.aktivitaet.id,
        inputDisz: this.newShootingBook.Disziplin.id,
        inputWaffe: this.newShootingBook.OwnWeapon.id,
        inputPlatz: this.newShootingBook.Place,
        datum: this.newShootingBook.Begin,
        trergges: this.external_TrGes,
        anz_schuss: this.external_Schuss
      };
      await this.$store.dispatch('UserApp/setNewExternalShootingBookRecord', Data)
          .then(this.set_defaultInputs())

    },
    set_defaultInputs() {
      this.newShootingBook = {
        shootingAction: {function: 'Schütze', value: 1},
        aktivitaet: {id: 1},
        OwnWeapon: this.$store.state.UserApp.Weapons[0],
        Disziplin: this.comp_OpenShootingBookData_DisziWeapon[0],
        Begin: new Date(),
      }
      this.$store.dispatch('UserApp/set_SelectedShootingRanges_to_Default')
    },
  },
}
</script>

<style scoped>

</style>