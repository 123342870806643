import Vue from 'vue'
import App from '../src/views/index.vue';
import vuetify from './plugins/vuetify'
import router from './plugins/router'
import store from './plugins/store/'
import {API} from "./plugins/axios"
import Swal from './plugins/SweetAlert';
import htmlToPaper from './plugins/HTMLtoPaper';
import ExcelExport from './plugins/ExcelExport';
import Chartkick from 'vue-chartkick';
import Chart from 'chart.js';
import VueSignaturePad from 'vue-signature-pad';
import VueHcaptcha from "@hcaptcha/vue-hcaptcha";
import { registerLicense } from '@syncfusion/ej2-base';

// Registering Syncfusion license key
registerLicense('Ngo9BigBOggjHTQxAR8/V1NAaF5cWWJCf1FpRmJGdld5fUVHYVZUTXxaS00DNHVRdkdnWXxfcnRcRGNeWER+W0Q=');

Vue.use(Chartkick.use(Chart))
Vue.use(VueSignaturePad);
Vue.component('vue-hcaptcha', VueHcaptcha);

Vue.config.productionTip = false

// Deactivate Vue DevTools in production
if (process.env.NODE_ENV === 'production') {
  Vue.config.devtools = false;
} else {
  Vue.config.devtools = true;
}

const token = localStorage.getItem('token')
if (token) {
  API.defaults.headers.common['Authorization'] = `Bearer ${token}`
}

new Vue({
  vuetify,
  store,
  router,
  Swal,
  htmlToPaper,
  ExcelExport,
  render: h => h(App)
}).$mount('#app')
