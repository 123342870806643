const state = {
    TextVwelcome: "",
    Shootingbook:[],
    ShootingbookApproval:[],
    TodayMember:[],
    TodaySA:[],
    TodaySL:[],
    ClubMember:[],
    VerbandAll:[],
    VerbandOwn:[],
    ShootingRanges:[],
    Weapons:[],
    Terminals:[],
    WorkingHours:[]

};

export default state;
