<template>
  <div class="mt-4  px-5">

    <h2>Vereine</h2>
    <v-alert dense type="info">
      <b>Hinweis:</b> Sobald der Verein deine Mitgliedschaft bestätigt hat, kannst du ihn in deinem Profil als Hauptverein auswählen.
    </v-alert>

    <v-data-table
        :headers="headers"
        :items="comp_myClub"
        sort-by="Vereinsnummer"
        class="elevation-1"
    >
      <template v-slot:top>
        <v-toolbar
            flat
        >
          <v-spacer></v-spacer>
          <suchen_zuordnen
              titel="Verein zuordnen"
              btn_title="Verein zuordnen"
              btn_icon="mdi-pistol"
              :content="comp_allClubs"
              :headers="headers_suche"
              @GET_SELECTION="save_club"/>

          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Willst du diesen Verein wirklich löschen?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Abbrechen</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Ja</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn icon
                 @click="getMyClubData">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.pivot.bestaetigt_vereinDatum="{ item }">
          <span v-if="item.pivot.bestaetigt_vereinDatum !=null">{{
              new Date(item.pivot.bestaetigt_vereinDatum.replace(" ", "T")).toLocaleString([], {dateStyle: 'short'})
            }}</span>
        <span v-else
              class="verein_offene_best">
              Bestätigung noch ausstehend
            </span>

      </template>


      <template v-slot:item.pivot.isVAdmin="{ item }">
        <v-icon
            v-if="item.pivot.isVAdmin == 1"
            color="success"

        >mdi-account-settings
        </v-icon>

      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn class="error my-1" text @click="deleteItem(item)">
          <v-icon class="mr-2">
            mdi-delete
          </v-icon>
          Löschen
        </v-btn>

      </template>
      <template v-slot:no-data>
        <v-btn
            color="primary"
            @click="initialize"
        >
          Reset
        </v-btn>
      </template>
    </v-data-table>
  </div>
</template>

<script>

import suchen_zuordnen from "@/views/component/suchen_zuordnen.vue";

export default {
  name: "myclub",
  components: {suchen_zuordnen},
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      {
        align: 'Vereinsnummer',
        sortable: false,
      },
      {text: 'Vereinsnummer', value: 'Vereinsnummer'},
      {text: 'Name', value: 'name'},
      {text: 'Ort', value: 'ort'},
      {text: 'PLZ', value: 'PLZ'},
      {text: 'Strasse', value: 'strasse'},
      {text: 'Bestätigt', value: 'pivot.bestaetigt_vereinDatum'},
      {text: 'Admin', value: 'pivot.isVAdmin'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],

    headers_suche: [
      {text: 'Name', value: 'name'},
      {text: 'Strasse', value: 'strasse'},
      {text: 'PLZ', value: 'PLZ'},
      {text: 'Ort', value: 'ort'}
    ],

    editedItem: [],
    delItem: [],
  }),

  computed: {
    comp_myClub() {
      return this.$store.state.UserApp.Clubs.meineVereine;
    },
    comp_allClubs() {
      return this.$store.state.UserApp.Clubs.Vereine;
    },

  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  mounted() {
  },

  methods: {

    getMyClubData() {
      this.$store.dispatch('UserApp/getClubs')
    },

    save_club(item) {
      var Data = {
        inputVereinID: item.id,
      };
      this.$store.dispatch('UserApp/setMyClub', Data)

    },

    save() {
      var Data = {
        inputVereinID: this.editedItem.id,
      };
      this.$store.dispatch('UserApp/setMyClub', Data)
          .then(this.dialog = false);
    },
    deleteItem(item) {
      this.delItem = item;
      this.dialogDelete = true;
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    deleteItemConfirm() {
      var Data = {
        inputDelVereinID: this.delItem.id,
      };
      this.$store.dispatch('UserApp/delMyClub', Data)
          .then(this.dialogDelete = false);
    },
    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    initialize() {
    },


  },
}
</script>

<style scoped>
.verein_offene_best {
  color: orange;
}
</style>
