<template>
  <div>
    <v-alert dense type="success">
      <div class="alert alert-info" role="alert">
        <b>Hinweis:</b> Alle offenen Einträge werden jede Nacht automatisch beendet!
      </div>
    </v-alert>

    <v-data-table
        :headers="headers"
        :items="comp_OpenShootingBookEntry"
        sort-by="nummer"
        class="elevation-1"
        no-data-text="Du hast zur Zeit keine offenen Einträge"
    >
      <template v-slot:top>
        <v-toolbar
            flat
            dense
            color="success"
        >
          <v-toolbar-title>offene Belegungen</v-toolbar-title>

          <v-spacer></v-spacer>
          <v-btn icon
                 @click="getOpenShootingBookEntry">
            <v-icon>mdi-reload</v-icon>
          </v-btn>

        </v-toolbar>
      </template>

      <template v-slot:item.funktion="{ item }">
        <span v-if="item.funktion === 1">Schütze</span>
        <span v-if="item.funktion === 2">Standaufsicht</span>
        <span v-if="item.funktion === 3">Schiesssportleiter</span>
      </template>

      <template v-slot:item.beginn="{ item }">
        <span>{{ new Date(item.beginn.replace(" ", "T")).toLocaleString() }}</span>
      </template>

      <template v-slot:item.actions="{ item }">
        <v-btn
            class="info"
            @click="editItems(item)"
        >
          <v-icon class="mr-2">
            mdi-pencil
          </v-icon>
          Edit
        </v-btn>
        <v-dialog
            v-model="dialog"
            transition="dialog-top-transition"
            width="800"
            :retain-focus="false"
        >
          <v-card>
            <v-card-title class="text-h5">
              Eintrag beenden
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                        label="Verein"
                        v-model="editItem.Club"
                        outlined
                        readonly
                        disabled
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                        label="Stand"
                        v-model="editItem.ClubRange"
                        outlined
                        readonly
                        disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                        label="Bahn"
                        v-model="editItem.Place"
                        outlined
                        readonly
                        disabled
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                        label="Waffe"
                        v-model="editItem.OwnWeapon"
                        outlined
                        readonly
                        disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-text-field
                        label="Beginn"
                        v-model="editItem.Begin"
                        outlined
                        readonly
                        disabled
                    ></v-text-field>
                  </v-col>
                  <v-col>
                    <v-text-field
                        label="Aufsicht"
                        v-model="editItem.aktivitaet"
                        outlined
                        readonly
                        disabled
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col>
                    <v-datetime-picker
                        label="Ende"
                        date-format="dd.MM.yyyy"
                        time-format="HH:mm"
                        v-model="editItem.Ende"
                        :retain-focus="false"
                        :text-field-props="textFieldProps"
                        :time-picker-props="timePickerProps"
                        :date-picker-props="datePickerProps"
                    >
                      <template slot="dateIcon">
                        <v-icon>mdi-calendar</v-icon>
                      </template>
                      <template slot="timeIcon">
                        <v-icon>mdi-clock-outline</v-icon>
                      </template>
                    </v-datetime-picker>

                  </v-col>
                </v-row>

                <v-divider></v-divider>
                <div class="text-h5 mb-3">Ergebniss erfassen:</div>

                <v-row>
                  <v-col>
                    <v-text-field
                        label="Schuss"
                        v-model="editItem.AnzSchuss"
                        outlined
                        type="number"
                        min="1"
                        max="5000"
                    >
                    </v-text-field>

                  </v-col>
                  <v-col>
                    <v-text-field
                        label="Ergebniss"
                        v-model="editItem.Summe"
                        outlined
                    >
                    </v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>

              <v-btn
                  color="green darken-1"
                  text
                  @click="dialog = false"
              >
                Abbrechen
              </v-btn>

              <v-btn
                  color="green darken-1"
                  text
                  @click="save()"
              >
                Speichern
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-btn
            class="error ml-2"
            @click="delShootingBookEntry_Dialog(item)"
        >
          <v-icon class="mr-2">
            mdi-trash-can
          </v-icon>
          löschen
        </v-btn>

      </template>

    </v-data-table>
    <v-dialog v-model="dialogDelete" max-width="500px" :retain-focus="false">
      <v-card>
        <v-card-title class="text-h5">Willst du diesen Eintrag wirklich löschen?</v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogDelete = false; prepare_delete_item = {}">Abbrechen</v-btn>
          <v-btn color="blue darken-1" text @click="delShootingBooKEntry()">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  name: "OpenShootingBookEntry",
  data: () => ({
    dialogDelete: false,
    headers: [
      {
        align: 'Verein',
        sortable: false,
      },
      {text: 'Verein', value: 'name'},
      {text: 'Funktion', value: 'funktion'},
      {text: 'Aktivität', value: 'aktivitaet'},
      {text: 'Stand', value: 'KurzBez'},
      {text: 'Bahn', value: 'bahn'},
      {text: 'Waffe', value: 'KurzT'},
      {text: 'Begin', value: 'beginn'},
      {text: 'Actions', value: 'actions', sortable: false},
    ],
    dialog: false,
    editItem: {
      SB_EDIT_ID: null,
      Club: null,
      shootingAction: null,
      aktivitaet: null,
      ClubRange: null,
      OwnWeapon: null,
      Disziplin: null,
      Begin: null,
      Place: null,
      Ende: new Date(),
      AnzSchuss: null,
      Summe: null,
    },
    anzahlschuss: [
      {id: 1, value: 10},
      {id: 2, value: 20},
      {id: 3, value: 30},
      {id: 4, value: 40},
      {id: 5, value: 50},
      {id: 6, value: 60},
    ],
    textFieldProps: {
      outlined: true,
    },
    datePickerProps: {},
    timePickerProps: {
      format: "24hr"
    },
    prepare_delete_item: {}
  }),
  computed: {
    comp_OpenShootingBookEntry: {
      get() {
        return this.$store.state.UserApp.OpenShootingBookEntry;
      },
      set() {
      },
    },

  },
  mounted() {
    this.getOpenShootingBookEntry();
  },
  methods: {
    getOpenShootingBookEntry() {
      this.$store.dispatch('UserApp/getOpenShootingBookEntry')
    },
    editItems(item) {
      this.dialog = true
      this.editItem.Club = item.name,
          this.editItem.shootingAction = item.funktion,
          this.editItem.aktivitaet = item.aktivitaet,
          this.editItem.ClubRange = item.KurzBez,
          this.editItem.OwnWeapon = item.KurzT,
          this.editItem.Begin = new Date(item.beginn.replace(" ", "T")).toLocaleString(),
          this.editItem.Place = item.bahn,
          this.editItem.SB_EDIT_ID = item.id,
          this.editItem.AnzSchuss = null,
          this.editItem.Summe = null
    },
    save() {
      var Data = {
        offeneEditID: this.editItem.SB_EDIT_ID,
        inputEditoffeneEnde: this.editItem.Ende,
        inputSchuss: this.editItem.AnzSchuss,
        inputEditoffeneTrErgGesamt: this.editItem.Summe,
      };
      this.$store.dispatch('UserApp/updateNewShootingBookRecord', Data)
          .then(this.dialog = false);
    },

    delShootingBookEntry_Dialog(item) {
      this.dialogDelete = true;
      this.prepare_delete_item = Object.assign({}, item)
    },

    delShootingBooKEntry() {
      const Data = {
        'shootingbook_id': this.prepare_delete_item.id
      }

      this.$store.dispatch('UserApp/delShootingbookEntry', Data)
          .then(this.dialogDelete = false,
              this.prepare_delete_item = {}
          )
    },
  },

}
</script>

<style scoped>

</style>
