<template>
  <div class="mt-4  px-5">
    <v-alert
        dense
        text
        shaped
        type="info">
      <b>Hinweis:</b>
      <div>
        Hier werden alle Arbeitsstunden dargestellt, die durch die schuetzenbuch.de Einträge als Standaufsicht und
        Schiessleiter entstanden sind.
      </div>
    </v-alert>
    <v-data-table
        :headers="headers"
        :items="comp_WorkingHours"
        sort-by="name"
        class="elevation-1"
        ref="WorkingHours"
        no-data-text="Keine Arbeitsstunden vorhanden, oder nicht geladen."
    >
      <template v-slot:top>
        <v-toolbar
            flat
            color="blue lighten-1"
        ><h2>Arbeitsstunden</h2>
          <v-spacer></v-spacer>
          <!-- Jahr Eingabe -->
          <v-text-field
              v-model="selectedYear"
              label="Jahr auswählen"
              type="number"
              min="1900"
              max="2100"
              class="year-picker"
              solo
              @change="validateAndFetchData"
          ></v-text-field>
          <working-hours-excel-export
              :excel_data="comp_WorkingHours"
              :name="comp_ClubName"
          ></working-hours-excel-export>
          <v-btn icon
                 @click="getWorkingHours">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
        </v-toolbar>
      </template>

      <template v-slot:item.Datum="{ item }">
        <span>{{
            new Date(item.Datum.replace(" ", "T")).toLocaleString([], {
              dateStyle: 'short'
            })
          }}</span>
      </template>

      <template v-slot:item.ende="{ item }">
        <span>{{
            new Date(item.ende.replace(" ", "T")).toLocaleString([], {
              dateStyle: 'short',
              timeStyle: 'short'
            })
          }}</span>
      </template>

      <template v-slot:item.beginn="{ item }">
        <span>{{
            new Date(item.beginn.replace(" ", "T")).toLocaleString([], {
              dateStyle: 'short',
              timeStyle: 'short'
            })
          }}</span>
      </template>


    </v-data-table>


  </div>
</template>

<script>
import WorkingHoursExcelExport from "@/views/component/WorkingHoursExcelExport.vue";
import swal from "sweetalert2";

export default {
  name: 'workinghours',
  components: {WorkingHoursExcelExport},
  data: () => ({
    dialog: false,
    dialogDelete: false,
    selectedYear: new Date().getFullYear(),
    headers: [
      {
        align: 'Name',
        sortable: false,
      },
      {text: 'Name', value: 'user_name'},
      {text: 'Vorname', value: 'user_vorname'},
      {text: 'Mitgliedsnummer', value: 'user_mitgliednummer'},
      {text: 'Datum', value: 'Datum'},
      {text: 'Stunden', value: 'stunden'},
      {text: 'Beginn', value: 'beginn'},
      {text: 'Ende', value: 'ende'},
      {text: 'Fuktion', value: 'funktion'},
      {text: 'Stand', value: 'standnamen'},
      {text: 'Beschreibung', value: 'Beschreibung'}
    ],


  }),

  computed: {
    comp_WorkingHours() {
      return this.$store.state.ClubApp.WorkingHours;
    },
    comp_ClubName() {
      return this.$store.getters['ClubAuth/isClubName'];
    }
  },

  mounted() {
    // this.getWorkingHours();
  },

  methods: {

    getWorkingHours() {
      const Data = {
        Year: this.selectedYear
      }
      this.$store.dispatch('ClubApp/getWorkingHours', Data)
    },

    validateAndFetchData() {
      if (
          this.selectedYear >= 1900 &&
          this.selectedYear <= 2100 &&
          Number.isInteger(Number(this.selectedYear))
      ) {
        this.getWorkingHours();
      } else {
        swal.fire("Bitte gib ein gültiges Jahr ein.");
      }
    },

    initialize() {
      this.getWorkingHours();
    },

  },
}
</script>

<style scoped>
.year-picker {
  max-width: 100px;
  padding-top: 25px;
  margin-left: 10px;
  margin-right: 30px;
}

.mySubheader {
  font-weight: bold;
}

#Hinweis_span {
  font-weight: bold;
}
</style>

