const getters = {
    GET_NavbarDrawer(state) {
        return state.NavigationDrawer;
    },
    GET_WeaponTransfer_Searched_Names(state) {
        return state.Weapon_Transfer_Search;
    },
    is_Websocket_Online(state){
        return state.websocket_online;
    }
}
export default getters;
