const mutations = {
    get_VWelcomeText(state, data) {
        state.TextVwelcome = data
    },
    get_Shootingbook(state, data) {
        state.Shootingbook = data
    },
    get_ShootingbookApproval(state, data) {
        state.ShootingbookApproval = data
    },
    get_TodayMember(state, data) {
        state.TodayMember = data
    },
    get_TodaySA(state, data) {
        state.TodaySA = data
    },
    get_TodaySL(state, data) {
        state.TodaySL = data
    },
    get_ClubMember(state, data) {
        state.ClubMember = data
    },
    get_Verband(state, {VerbandAll, VerbandOwn}) {
        state.VerbandAll = VerbandAll,
            state.VerbandOwn = VerbandOwn
    },
    get_shootingrange(state, data) {
        state.ShootingRanges = data
    },
    get_Weapons(state, data) {
        state.Weapons = data
    },
    get_Terminals(state, data) {
        state.Terminals = data
    },
    set_WorkingHours(state, data){
        state.WorkingHours = data
    },

    set_appDataClear(state) {
        state.TextVwelcome = "",
            state.Shootingbook = [],
            state.ShootingbookApproval = [],
            state.TodayMember = [],
            state.TodaySA = [],
            state.TodaySL = [],
            state.ClubMember = [],
            state.VerbandAll = [],
            state.VerbandOwn = [],
            state.ShootingRanges = [],
            state.Weapons = [],
            state.Terminals = [],
            state.WorkingHours = []
    },
};

export default mutations;
