<template>
  <div class="mt-4  px-5">
    <v-data-table
        :headers="headers_suche"
        :items="comp_VerbandOwn"
        sort-by="name"
        class="elevation-1"
        ref="Verband"
    >

      <template v-slot:top>
        <v-toolbar
            flat
            color="success"
        ><h3>Verbände</h3>
          <v-spacer></v-spacer>
          <suchen_zuordnen
              titel="weiteren Verband zuordnen"
              btn_title="Weitere Verbände"
              btn_icon="mdi-ticket-confirmation"
              :content="comp_VerbandAll"
              :headers="headers_suche"
              @GET_SELECTION="attachVerband"/>
          <v-btn icon
                 @click="getVerband">
            <v-icon>mdi-reload</v-icon>
          </v-btn>

        </v-toolbar>
      </template>


          <template v-slot:item.action="{ item }">

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="text-h5">Willst du diesen Eintrag wirklich löschen?</v-card-title>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                  <v-btn color="blue darken-1" text @click="dettachVerband(item)">OK</v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-btn
                class="btn-error"
                color="error"
                @click="deleteItem"
            >
              <v-icon>
                mdi-delete
              </v-icon>
            </v-btn>

          </template>

          <template v-slot:no-data>
            <v-btn
                color="primary"
                @click="initialize"
            >
              Reset
            </v-btn>
          </template>
    </v-data-table>
  </div>
</template>

<script>
import suchen_zuordnen from "@/views/component/suchen_zuordnen.vue";

export default {
  name: 'verbaende',
  components: {suchen_zuordnen},
  data: () => ({
    dialog: false,
    dialogDelete: false,
    loading: false,
    headers: [
      {
        align: 'Name',
        sortable: false,
      },
      {text: 'Kurz', value: 'Kurz'},
      {text: 'Name', value: 'Name'},
      {text: 'Strasse', value: 'Strasse'},
      {text: 'PLZ', value: 'PLZ'},
      {text: 'Ort', value: 'Ort'},
      {text: 'Action', value: 'action', sortable: false}
    ],

    headers_suche: [
      {text: 'Kurz', value: 'Kurz'},
      {text: 'Name', value: 'Name'},
      {text: 'Strasse', value: 'Strasse'},
      {text: 'PLZ', value: 'PLZ'},
      {text: 'Ort', value: 'Ort'}
    ],

    editedItem: [],
  }),

  computed: {
    comp_VerbandAll() {
      return this.$store.state.ClubApp.VerbandAll;
    },
    comp_VerbandOwn() {
      return this.$store.state.ClubApp.VerbandOwn;
    },

  },

  mounted() {
    this.getVerband();
  },

  methods: {

    getVerband() {
      this.$store.dispatch('ClubApp/getVerband')
    },

    attachVerband(item) {
      if (item) {
        var Data = {
          inputVerbandID: item.id,
          attach: 1,
        };
        this.$store.dispatch('ClubApp/setVerband', Data)
            .then(this.dialog = false)
      }
    },
    dettachVerband(item) {
      this.loading = true;
      var Data = {
        inputVerbandID: item.id,
        attach: 0,
      };
      this.$store.dispatch('ClubApp/setVerband', Data)
          .then(this.dialog = false,
              this.loading = false,
              this.dialogDelete = false
          );

    },
    deleteItem() {
      this.dialogDelete = true;
    },
    initialize() {
      this.getVerband();
    },
    close() {
      this.dialog = false;
    },
    closeDelete() {
      this.dialogDelete = false;
    },

  },
}
</script>

<style scoped>

</style>

