<template>
  <div class="mt-4  px-5">
    <v-card>
      <v-card-title>
        Passwort ändern
      </v-card-title>
      <v-card-text>

        <v-form
            ref="form"
            v-model="valid">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    v-model="password_old"
                    name="input-10-1"
                    hint=""
                    label="Altes Passwort"
                    :rules="[rules.required, rules.min]"
                    :type="showPW_OLD ? 'text' : 'password'"
                    @click:append="showPW_OLD = !showPW_OLD"
                    :append-icon="showPW_OLD ? 'mdi-eye' : 'mdi-eye-off'"
                    required
                    outlined
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12">
                <v-text-field
                    v-model="password"
                    name="input-10-1"
                    hint=""
                    label="Passwort"
                    :rules="[rules.required, rules.min]"
                    :type="showPW1 ? 'text' : 'password'"
                    @click:append="showPW1 = !showPW1"
                    :append-icon="showPW1 ? 'mdi-eye' : 'mdi-eye-off'"
                    required
                    outlined
                ></v-text-field>
                <v-progress-linear
                    :background-opacity="opacity"
                    :color="score.color"
                    :value="score.value"
                    height="5"
                    rounded
                ></v-progress-linear>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    v-model="password1"
                    name="input-10-1"
                    hint=""
                    label="Passwort wiederholen"
                    :rules="[rules.required, passwordConfirmationRule]"
                    :type="showPW2 ? 'text' : 'password'"
                    @click:append="showPW2 = !showPW2"
                    :append-icon="showPW2 ? 'mdi-eye' : 'mdi-eye-off'"
                    required
                    outlined
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-btn
                  color="primary"
                  @click="save"
              >
                passwort ändern
              </v-btn>
            </v-row>

          </v-container>
        </v-form>
      </v-card-text>
    </v-card>
  </div>

</template>

<script>
import zxcvbn from "zxcvbn";

export default {
  name: 'changePassword',
  data() {
    return {
      valid: false,
      password_old: "",
      password: "",
      password1: "",
      showPW_OLD: false,
      showPW1: false,
      showPW2: false,
      opacity: null,
      rules: {
        required: value => !!value || 'Passwort eingeben',
        min: v => v.length >= 10 || 'Das Passwort muss mindestens 10 Zeichen lang sein.',
        strength: v => zxcvbn(v).score >= 3 || 'Ein stärkeres Passwort wählen! Es müssen Zeichen, Zahlen sowie Sonderzeichen vorhanden sein!',
      },
    }
  },
  computed: {
    passwordConfirmationRule() {
      return () => (this.password === this.password1) || 'Die Passwörter stimmen nicht überein!'
    },
    score() {
      const result = zxcvbn(this.password);

      switch (result.score) {
        case 4:
          return {
            color: "light-blue",
            value: 100
          };
        case 3:
          return {
            color: "light-green",
            value: 75
          };
        case 2:
          return {
            color: "yellow",
            value: 50
          };
        case 1:
          return {
            color: "orange",
            value: 25
          };
        default:
          return {
            color: "red",
            value: 0
          };
      }
    }
  },
  methods: {
    async save() {
      var Data = {
        current_password: this.password_old,
        new_password: this.password,
        new_password_confirmation: this.password1
      }
      await this.$store.dispatch('ClubAuth/setNewPassword', Data)
          .then(
              // this.$refs.form.reset()
          )
    },

  },
}
</script>

<style scoped>

</style>